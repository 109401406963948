<template>
  <a-modal
      title="导入帖子数据"
      :visible="visible"
      :confirm-loading="confirmLoading"
      :maskClosable="false"
      ok-text="立即导入"
      @ok="handleOk"
      @cancel="handleCancel"
      @keyup.enter.native="handleOk"
  >
    <div>
      <a-upload-dragger
          name="file"
          withCredentials
          accept=".xls, .xlsx"
          :multiple="false"
          :data="{bucketType:'public',uploadType:'excel'}"
          :headers="headers"
          :fileList="fileList"
          action="admin/brand/import"
          :before-upload="beforeUpload"
          @change="handleFileChange"
      >
        <p class="ant-upload-drag-icon">
          <a-icon type="inbox"/>
        </p>
        <p class="ant-upload-text">
          点击选择Excel文件，或者拖动文件到此区域
        </p>
      </a-upload-dragger>
      <div style="margin-top:30px">
        <a href="/template/公司评价收集模板.xlsx" target="_blank">公司评价收集模板.xlsx</a>
      </div>
    </div>
  </a-modal>
</template>
<script>
import {MessageBox} from 'element-ui';

export default {
  name: 'ImportDataDialog',
  data() {
    return {
      visible: false,
      confirmLoading: false,
      fileList: []
    }
  },
  computed: {
    headers() {
      return {
        'Authentication': this.$store.state.account.token,
        clientType: 'pc_console'
      }
    }
  },
  methods: {
    showModal() {
      this.visible = true
      this.fileList = []
    },

    handleCancel() {
      this.visible = false
    },

    handleFileChange(info) {
      console.log(info)
      if (info.file.status === 'uploading') {
        return
      }
    },

    beforeUpload(file) {
      const fileSuffix = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase()

      const whiteList = ['xls', 'xlsx']
      if (whiteList.indexOf(fileSuffix) === -1) {
        this.$message.error('上传文件只能是 xls、xlsx格式')
        return false
      }

      const isLt2M = file.size / 1024 / 1024 <= 10
      if (!isLt2M) {
        this.$message.error('文件大小不能超过10MB!')
        return false
      }
      this.fileList.push(file)
      return false//控制手动上传
      // return isLt2M
    },
    handleOk() {
      if (this.fileList.length < 1) {
        this.$message.warn('请选择文件!')
        return
      }
      const formData = new FormData()
      formData.append('file', this.fileList[0])
      this.confirmLoading = true
      // You can use any AJAX library you like
      this.$upload('import/importExcel', formData).then(r => {
        this.$emit("success")
        this.visible = false
        this.confirmLoading = false
        MessageBox.alert(`导入完成，经过排重后，本次共导入${r.data}条数据`)
      }).catch(() => {
        this.confirmLoading = false
      })
    },
  },
}
</script>
<style lang="less" scoped>

.logo-img {
  width: 86px;
  height: 86px;
}
</style>
