<template>
  <a-card :bordered="false" class="card-area">
    <div class="search">
      <!-- 搜索区域 -->
      <div class="items" style="flex: none">
        <div class="one-item" style="width:150px;">
          <a-select
              class="input"
              v-model="queryParams.status"
              allowClear
              placeholder="导入状态"
              @change="search(1)"
          >
            <a-select-option
                v-for="item in dict['importPostStatus']"
                :value="item.value"
                :key="item.value"
            >{{ item.label }}
            </a-select-option>
          </a-select>
        </div>
        <div class="one-item" style="width:150px;">
          <a-input
              class="input"
              v-model="queryParams.company"
              placeholder="公司"
              allowClear
          />
        </div>
        <div class="one-item">
          <a-input
              class="input"
              v-model="queryParams.title"
              placeholder="标题"
              allowClear
          />
        </div>
        <div class="one-item" style="width:150px;">
          <a-input
              class="input"
              v-model="queryParams.source"
              placeholder="来源"
              allowClear
          />
        </div>
      </div>
      <div class="operator">
        <a-button type="primary" @click="search(1)">查询</a-button>
        <a-button style="margin-left: 8px" @click="reset">重置</a-button>
        <a-button type="danger" style="margin-left: 30px" @click="importExcel">Step 1：导Excel入数据到临时表</a-button>
        <a-badge :count="countInfo.toImportCount||0" :overflowCount="9999999999" show-zero>
          <a-button type="danger" style="margin-left: 30px" @click="importData">Step 2：导入临时数据到正式表</a-button>
        </a-badge>
        <a-badge :count="countInfo.toAuditCount||0" :overflowCount="9999999999" show-zero>
          <a-button type="danger" style="margin-left: 30px" @click="autoAudit">Step 3：自动审核</a-button>
        </a-badge>
      </div>
    </div>
    <a-table
        ref="TableInfo"
        size="small"
        :bordered="true"
        :columns="columns"
        rowKey="id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
        :scroll="{ x: 1000 }"
    >
      <template slot="status" slot-scope="text, record">
        <a-tag :color="record.status | dictName('importPostStatus', 'color')">{{
            record.status | dictName("importPostStatus")
          }}
        </a-tag>
      </template>

      <template slot="operation" slot-scope="text, record">
        <a-dropdown>
          <a-menu slot="overlay">

            <a-menu-item
                key="delete"
                style="color: red"
                v-hasPermission="'topic:delete'"
                @click="getDelete($event, record)"
            >
              <a-icon type="delete"/>
              删除
            </a-menu-item>
          </a-menu>
          <a-button>
            操作
            <a-icon type="down"/>
          </a-button>
        </a-dropdown>
      </template>
      <!-- 删除中提示页面-->
    </a-table>
    <a-modal
        title=""
        :visible="showLoading"
        :maskClosable="false"
        :closable="false"
        centered
        :footer="null"
    >
      <div style="font-size: 1.2em;color:red;font-weight: 700;text-align: center">
        <a-icon type="loading"/>
        正在处理，请稍候...（请勿关闭当前窗口，请勿刷新页面）
      </div>
    </a-modal>
    <import-data-dialog ref="importDialog" @success="search(1)"/>
  </a-card>
</template>
<script>
import {ListMixin} from "@/mixin/list-mixin";
import ImportDataDialog from "./ImportDataDialog";

export default {
  name: "ImportDataList",
  components: {ImportDataDialog},
  mixins: [ListMixin],
  data() {
    return {
      listGetUrl: "import/listByAdmin",
      listMethod: "get",
      showLoading: false,
      countInfo: {}
    };
  },
  computed: {
    columns() {
      let {sortedInfo, filteredInfo} = this;
      sortedInfo = sortedInfo || {};
      filteredInfo = filteredInfo || {};
      return [
        {
          title: "ID",
          width: 80,
          align: "center",
          dataIndex: "id",
        },
        {
          title: "公司名称",
          align: "center",
          width: 150,
          dataIndex: "company",
        },
        {
          title: "标题",
          width: 150,
          align: "center",
          dataIndex: "title",
        },
        {
          title: "状态",
          align: "center",
          width: 100,
          dataIndex: "status",
          scopedSlots: {customRender: "status"},
        },
        {
          title: "内容",
          width: 300,
          ellipsis: true,
          dataIndex: "content",
        },
        {
          title: "城市",
          align: "center",
          width: 80,
          dataIndex: "city",
        },
        {
          title: "来源",
          width: 100,
          align: "center",
          dataIndex: "source",
        },
        {
          title: "角色",
          align: "center",
          width: 80,
          dataIndex: "dimType",
        },
        {
          title: "评分-工作环境",
          align: "center",
          width: 120,
          dataIndex: "工作环境",//jobRate
        },
        {
          title: "评分-薪资福利",
          align: "center",
          width: 120,
          dataIndex: "薪资福利",//salaryRate
        },
        {
          title: "评分-文化氛围",
          align: "center",
          width: 120,
          dataIndex: "文化氛围",//cultureRate
        },
        {
          title: "评分-内部管理",
          align: "center",
          width: 120,
          dataIndex: "内部管理",//internalRate
        },
        {
          title: "评分-职业发展",
          align: "center",
          width: 120,
          dataIndex: "职业发展",//careerRate
        },
        {
          title: "评分-团队合作",
          align: "center",
          width: 120,
          dataIndex: "团队合作",//teamRate
        },
        {
          title: "备注",
          align: "center",
          width: 80,
          ellipsis: true,
          dataIndex: "remark",
        },
        {
          title: "关联评论]",
          align: "center",
          width: 150,
          ellipsis: true,
          dataIndex: "commentItems",
        },
        {
          title: "建立时间",
          width: 150,
          align: "center",
          dataIndex: "createDate",
        },
        {
          title: "操作",
          align: "center",
          dataIndex: "operation",
          fixed: "right",
          scopedSlots: {customRender: "operation"},
        },
        // {}
      ];
    },
  },

  mounted() {
    this.search();
    this.getCountInfo();
  },
  methods: {
    getCountInfo() {
      this.$get("import/countInfo").then((res) => {
        this.countInfo = res.data || {}
      });
    },
    getDelete(e, record) {
      let that = this;

      this.$confirm({
        title: "删除确认",
        content: "您确定要删除【" + record.circleName + "】圈子吗？！",
        centered: true,
        onOk() {
          that.$postJson("import/delete", [record.id]).then(() => {
            that.$message.success("删除成功");
            that.search();
          });
        },
      });
    },
    importExcel() {
      this.$refs.importDialog.showModal();
    },
    importData() {
      const that = this;
      this.$confirm({
        title: "导入确认",
        content: "手工处理每次导入1000行（需要导入更多可以多次执行）。开始导入吗？",
        centered: true,
        onOk() {
          that.showLoading = true
          that.$postJson("import/importData").then((res) => {
            that.$message.success("导入成功【" + res.data + "】行数据");
            that.getCountInfo();
            that.search(1);
            that.showLoading = false
          }).catch(() => {
            that.showLoading = false
          });
        },
      });

    },
    autoAudit() {
      const that = this;
      this.$confirm({
        title: "导入确认",
        content: "注意：这里审核只针对导入数据，不能审核人工输入数据！手工处理每次审核1000行（需要导入更多可以多次执行），开始自动审核吗？",
        centered: true,
        onOk() {
          that.showLoading = true
          that.$postJson("import/autoAudit").then((res) => {
            that.$message.success("审核成功【" + res.data + "】行数据");
            that.search(1);
            that.getCountInfo();
            that.showLoading = false
          }).catch(() => {
            that.showLoading = false
          });
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.textClick {
  // color: #1890ff;
  cursor: pointer;
  text-decoration: underline;
}

.img-list {
  display: flex;
  justify-content: center;
}
</style>
